import './App.css';
import { Alert, AlertTitle, BottomNavigation, BottomNavigationAction, Button, Collapse, Fab, Grow, Paper, Slide } from '@mui/material';
import { Add, DoneAll, Forest, RequestQuote } from '@mui/icons-material';
import If, { IfLet } from './util/If';
import defaultJob, { Job, JobState, jobState } from './util/Types';
import { useContext, useEffect, useMemo, useState } from 'react';
import JobDialog from './jobs/JobDialog';
import FullscreenProgress from './util/FullscreenProgress';
import useFirestore from './util/useFirestore';
import SearchContext from './filter/SearchContext';
import { defaultFilter, processFilters, processSearch } from './filter/SearchUtil';
import Version from './util/Version';
import AuthWall from './auth/AuthWall';
import JobSection from './jobs/JobSection';

function getSavedTab(): JobState {
  let value = localStorage.getItem('ats_tab')
  let parsed = parseInt(value ?? "")
  if (![0, 1, 2].includes(parsed))
      return JobState.ACTIVE
  return parsed
}

function stateName(state: JobState): string {
  switch (state) {
    case JobState.BID: return "Bids"
    case JobState.ACTIVE: return "Active"
    case JobState.CLOSED: return "Closed"
  }
}

function AppInternal() {
  const search = useContext(SearchContext)
  const [currentJob, setCurrentJob] = useState<Job | null>(null)
  const __jobs = useFirestore<Job>('jobs')
  const _jobs = useMemo(() => __jobs === null || 'code' in __jobs ? null : __jobs, [__jobs])
  const error = useMemo(() => __jobs && 'code' in __jobs ? __jobs : null, [__jobs])
  const results: Job[] | null = useMemo(() => _jobs ? processSearch(processFilters(_jobs, search.filters), search.searchQuery.trim()) : null, [_jobs, search.filters, search.searchQuery])
  const filtersActive = useMemo(() => _jobs?.length !== results?.length, [_jobs, results])
  const [tab, setTab] = useState<JobState>(getSavedTab())
  useEffect(() => {
    setCurrentJob(current => current && _jobs ? _jobs.find(j => j.id === current.id) || null : null)
  }, [setCurrentJob, _jobs])
  useEffect(() => localStorage.setItem('ats_tab', tab.toString()), [tab])
  const jobsCount = useMemo(() => _jobs?.filter(j => jobState(j) === tab).length || 0, [_jobs, tab])
  const selectedJobs = useMemo(() => results?.filter(j => jobState(j) === tab), [results, tab])
  const sectionTitle = useMemo(() => stateName(tab), [tab])
  return <>
    <IfLet value={error}>{e => <>
      <Alert sx={{ mb: 1 }} severity='error' variant='filled' action={<Button color='inherit' size='small' onClick={() => { window.location.reload() }}>Reload Page</Button>}>
        <AlertTitle>Fatal Error</AlertTitle>
        A fatal error occurred while loading jobs: {JSON.stringify(e)}
      </Alert>
    </>}</IfLet>
    <If condition={results === null}>
      <FullscreenProgress/>
    </If>
    <IfLet value={selectedJobs}>{jobs =>
      <>
        <Collapse in={filtersActive}>
          <Grow in={filtersActive}>
            <Alert sx={{mb: 1}} severity='info' variant='filled' action={<Button color='inherit' size='small' onClick={() => { search.setSearchQuery(''); search.setFilters(defaultFilter) }}>Reset</Button>}>
              <AlertTitle>Showing Only Matching Results</AlertTitle>
              The jobs below are the results of your search query (sorted by closest match) and/or filters. Tap Reset to clear all filters and see all jobs.
            </Alert>
          </Grow>
        </Collapse>
        <JobSection title={sectionTitle} totalCount={jobsCount} jobs={jobs} bids={tab === JobState.BID} onSelect={setCurrentJob} />
        <If condition={_jobs !== null}>
          <Version pt={2} />
        </If>
        <JobDialog job={currentJob} onClose={() => setCurrentJob(null)} />
      </>
    }</IfLet>
    <Grow unmountOnExit in={results !== null}>
      <Fab sx={{ position: 'fixed', bottom: t => t.spacing(10), right: t => t.spacing(3) }} onClick={() => setCurrentJob(defaultJob())
      } variant='extended' color='atsGreen'>
        <Add sx={{ mr: 1 }} /> Job
      </Fab>
    </Grow>
    <Slide direction='up' unmountOnExit in={results !== null}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels value={tab} onChange={(_, newValue) => setTab(newValue)}>
          <BottomNavigationAction href='#' value={JobState.BID} label={stateName(JobState.BID)} icon={<RequestQuote />} />
          <BottomNavigationAction href='#' value={JobState.ACTIVE} label={stateName(JobState.ACTIVE)} icon={<Forest />} />
          <BottomNavigationAction href='#' value={JobState.CLOSED} label={stateName(JobState.CLOSED)} icon={<DoneAll />} />
        </BottomNavigation>
      </Paper>
    </Slide>
  </>
}

export default function App() {
  return <AuthWall>
    <AppInternal />
  </AuthWall>
};
