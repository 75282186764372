import { PriorityHigh, NotificationImportant, PinDrop, Person, Photo, Today, ChevronRight, Phone, Email, CopyAll } from "@mui/icons-material"
import { useTheme, Box, Card, CardActionArea, CardContent, Stack, Typography, Divider, CardActions, Button } from "@mui/material"
import { useState, useCallback, useMemo } from "react"
import BasicSnackbar from "../util/BasicSnackbar"
import HSpacer from "../util/HSpacer"
import If, { IfLet } from "../util/If"
import { Job } from "../util/Types"

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
)

export default function JobCard(props: {job: Job, bids: boolean, onClick: (arg0: Job) => void}) {
  let theme = useTheme()
  let [copyNotification, setCopyNotification] = useState(false)
  let copyAddress = useCallback(() => {
    let street = [props.job.address.line1, props.job.address.line2].filter(l => l.length > 0).join(' ')
    let cityStateZip = [props.job.address.city, props.job.address.state, props.job.address.zip].filter(l => l.length > 0).join(', ')
    navigator.clipboard.writeText([street, cityStateZip].filter(l => l.length > 0).join(', ')).then(() => setCopyNotification(true))
  }, [props.job, setCopyNotification])
  const hasPhoneNumber = useMemo(() => props.job.phone.length > 0, [props.job])
  const hasEmail = useMemo(() => props.job.email.length > 0, [props.job])
  const hasAddress = useMemo(() => [props.job.address.line1, props.job.address.city, props.job.address.state].every(l => l.length > 0), [props.job])
  return <Card elevation={2}>
    <CardActionArea onClick={() => props.onClick(props.job)}>
      <CardContent>
        <Stack direction='row'>
          <Stack direction='column' spacing={1}>
            <Stack direction='row' spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <If condition={props.job.priority === true}>
                <PriorityHigh fontSize='small' color='error' />
              </If>
              <If condition={props.bids && props.job.public === true}>
                <NotificationImportant fontSize='small' color='warning' />
              </If>
              <Typography variant='h6'>
                <If condition={props.job.customerName.length > 0}>
                  {props.job.customerName}
                </If>
                <If condition={props.job.customerName.length === 0}>
                  <Typography sx={{...theme.typography.subtitle1, fontWeight: 'bold', display: 'inline', color: theme.palette.text.secondary}}>No name</Typography>
                </If>
                <If condition={props.job.estimatedPrice.length > 0}><>
                  {bull} {isNaN(parseInt(props.job.estimatedPrice)) ? props.job.estimatedPrice : `$${props.job.estimatedPrice}`}
                </></If>
                <IfLet value={props.job.estimatedHours}>{h => <>
                  {bull} {h}hrs
                </>}</IfLet>
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ ...theme.typography.body2, justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }} useFlexGap divider={<Divider orientation='vertical' variant='fullWidth' flexItem />}>
              {hasAddress && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <PinDrop fontSize='small' />
                <Typography fontSize='inherit'>{props.job.address.city}</Typography>
              </Stack>}
              {props.job.contactName && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Person fontSize='small' />
                <Typography fontSize='inherit'>{props.job.contactName}</Typography>
              </Stack>}
              {props.job.attachments.length > 0 && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Photo fontSize='small' />
                <Typography fontSize='inherit'>{props.job.attachments.length}</Typography>
              </Stack>}
              <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Today fontSize='small' />
                <Typography fontSize='inherit'>{new Date(props.job.createdAt).toLocaleString(undefined, {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true
                })}</Typography>
              </Stack>
            </Stack>
            <If condition={!!props.job.notes}>
              <Typography color='textSecondary' fontStyle='italic'>
                {props.job.notes}
              </Typography>
            </If>
          </Stack>
          <HSpacer />
          <ChevronRight fontSize='medium' sx={{ my: 'auto' }} />
        </Stack>
      </CardContent>
    </CardActionArea>
    <If condition={hasPhoneNumber || hasEmail || hasAddress}>
      <Divider/>
      <CardActions>
        <If condition={hasPhoneNumber}>
          <Button href={`tel:+1${props.job.phone.trim().replaceAll('-', '')}`} size='small' startIcon={<Phone />}>Call</Button>
        </If>
        <If condition={hasEmail}>
          <Button href={`mailto:${props.job.email}`} size='small' startIcon={<Email />}>Email</Button>
        </If>
        <If condition={hasAddress}>
          <Button onClick={copyAddress} size='small' startIcon={<CopyAll />}>Address</Button>
        </If>
      </CardActions>
    </If>
    <BasicSnackbar open={copyNotification} onClose={() => setCopyNotification(false)} color='success' message='Address copied to clipboard.'/>
  </Card>
}