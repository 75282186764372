import { Divider, Grid2 as Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { states } from "./jobs/JobDetails";
import { useCallback, useState, FormEvent } from "react";
import { useConfirm } from "material-ui-confirm";
import { LoadingButton } from "@mui/lab";
import { signInAnonymously } from "firebase/auth";
import { FirebaseAuth, FirebaseFirestore } from "./FirebaseApp";
import { collection, doc, setDoc } from "firebase/firestore";
import { textFieldValue } from "./jobs/JobDialog";
import defaultJob, { Job, JobState } from "./util/Types";

export default function PublicRequestForm() {
    let [loading, setLoading] = useState(false)
    const confirm = useConfirm()
    const error = useCallback(() => confirm({
        title: "Something went wrong",
        description: "Sorry, an error occurred and your request could not be submitted. Please try again later.",
        allowClose: false,
        hideCancelButton: true
    }).finally(() => setLoading(false)), [confirm, setLoading])
    const createJob = useCallback(() => {
        if (FirebaseFirestore) {
            let job = defaultJob()
            let notes = textFieldValue('notes').trim()
            return setDoc(doc(collection(FirebaseFirestore, 'jobs'), job.id), {
                ...job,
                key: job.id,
                public: true,
                active: true,
                state: JobState.BID,
                customerName: textFieldValue('customerName').trim(),
                contactName: textFieldValue('contactName').trim(),
                phone: textFieldValue('phone').trim().replaceAll(/[^0-9]/g, ''),
                email: textFieldValue('email').trim(),
                address: {
                    line1: textFieldValue('addressLine1').trim(),
                    line2: textFieldValue('addressLine2').trim(),
                    city: textFieldValue('addressCity').trim(),
                    state: textFieldValue('addressState'),
                    zip: textFieldValue('addressZip').trim()
                },
                notes: notes.length > 0 ? "[Request for estimate]: " + notes : '[Request for estimate]'
            } as Job).then(() => {
                setLoading(false)
                return confirm({
                    title: "Thank you",
                    description: "Your estimate request was submitted. Our team will follow up with you soon.",
                    allowClose: false,
                    buttonOrder: []
                }).finally(() => window.location.reload())
            }, e => {
                console.error('Failed to create document', e)
                return error()
            })
        } else {
            console.error('FirebaseFirestore instance null')
            return error()
        }
    }, [setLoading, confirm, error])
    const submit = useCallback((event: FormEvent<any>) => {
        event.preventDefault()
        if (FirebaseAuth) {
            setLoading(true)
            return FirebaseAuth.currentUser ? createJob() : signInAnonymously(FirebaseAuth).then(() => createJob(), e => {
                console.error('Anonymous auth failed', e)
                return error()
            })
        } else {
            console.error('FirebaseAuth instance null')
            return error()
        }
    }, [setLoading, error, createJob])

    return <>
        <form onSubmit={submit}>
            <Stack direction='column' spacing={4} divider={<Divider flexItem />}>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <Typography variant="h5">Request Estimate</Typography>
                        <Typography variant="caption">Fields marked with * are required.</Typography>
                    </Grid>
                    <Grid size={12}>
                        <TextField disabled={loading} required id="customerName" fullWidth variant="filled" label="Your name or business name" />
                    </Grid>
                    <Grid size={6}>
                        <TextField disabled={loading} id="contactName" fullWidth variant="filled" label="Contact name" helperText="If different than the name above" />
                    </Grid>
                    <Grid size={6}>
                        <TextField disabled={loading} required id="phone" fullWidth variant="filled" type="phone" label="Phone number" helperText="Digits only" />
                    </Grid>
                    <Grid size={12}>
                        <TextField disabled={loading} required id="email" fullWidth variant="filled" type="email" label="Email address" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <Typography variant="h6">Address</Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField disabled={loading} required id="addressLine1" fullWidth variant="filled" label="Line 1" />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField disabled={loading} id="addressLine2" fullWidth variant="filled" label="Line 2" />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <TextField disabled={loading} required id="addressCity" fullWidth variant="filled" label="City" />
                    </Grid>
                    <Grid size={{ xs: 6, sm: 4 }}>
                        <TextField disabled={loading} required id="addressZip" fullWidth variant="filled" label="Zip code" />
                    </Grid>
                    <Grid size={{ xs: 6, sm: 2 }}>
                        <Select disabled={loading} required inputProps={{ id: 'addressState' }} fullWidth variant="filled" label="State" defaultValue="TX">
                            {states.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <Typography variant="h6">Notes</Typography>
                    </Grid>
                    <Grid size={12}>
                        <TextField disabled={loading} id="notes" label="Notes" variant="filled" fullWidth multiline rows={10} />
                    </Grid>
                </Grid>
                <LoadingButton type="submit" loading={loading} size="large" variant="contained" color="atsGreen">Submit</LoadingButton>
            </Stack>
        </form>
    </>
}