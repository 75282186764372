import { User } from "firebase/auth"

export type UploadState = 'finishing' | { current: number, count: number }

export type AuthState = 'loading' | 'unauthenticated' | User

export enum JobState {
    BID = 0,
    ACTIVE = 1,
    CLOSED = 2
}

export type Job = {
    id: string,
    public?: boolean
    active: boolean,
    state?: number,
    priority: boolean,
    customerName: string,
    contactName: string,
    phone: string,
    email: string,
    address: {
        line1: string,
        line2: string,
        city: string,
        state: string,
        zip: string
    }
    attachments: string[],
    notes: string,
    estimatedHours: number | null,
    estimatedPrice: string,
    complexity: number,
    equipment: string[],
    createdAt: number
}

export default function defaultJob(): Job {
    return {
        id: Date.now().toString(36).toUpperCase(),
        public: false,
        active: true, // TODO: deprecated
        state: JobState.BID,
        priority: false,
        customerName: '',
        contactName: '',
        phone: '',
        email: '',
        address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            zip: ''
        },
        attachments: [],
        notes: '',
        estimatedHours: null,
        estimatedPrice: '',
        complexity: 0,
        equipment: [],
        createdAt: Date.now()
    }
}

export function jobState(job: Job): JobState {
    if ([0, 1, 2].includes(job.state ?? -1))
        return job.state as JobState
    else return job.active ? JobState.ACTIVE : JobState.CLOSED
}