import { Photo } from "@mui/icons-material";
import { useMediaQuery, Dialog, DialogTitle, Stack, Theme, Typography, Button, DialogContent, DialogActions, Divider, Alert, AlertTitle, Collapse, Fade, DialogContentText } from "@mui/material";
import JobDetails, { equipmentTypes } from "./JobDetails";
import { Job, jobState, JobState } from "../util/Types";
import HSpacer from "../util/HSpacer";
import If, { IfLet } from "../util/If";
import { useCallback, useMemo, useState } from "react";
import PhotosDialog from "../photos/PhotosDialog";
import { collection, deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { FirebaseFirestore, FirebaseStorage } from "../FirebaseApp";
import { useConfirm } from "material-ui-confirm";
import { deleteObject, ref } from "firebase/storage";
import BasicSnackbar from "../util/BasicSnackbar";

export function textFieldValue(id: string): string {
    return (document.getElementById(id) as HTMLInputElement).value
}

function checkboxValue(id: string): boolean {
    return (document.getElementById(id) as HTMLInputElement).checked
}

function numericValue(id: string): number {
    return (document.getElementById(id) as HTMLInputElement).valueAsNumber
}

export default function JobDialog({job, onClose}: {job: Job | null, onClose: () => void}) {
    const confirm = useConfirm()
    const isNew = useMemo(() => (job && !('key' in job)) || false, [job])
    const fullScreen = useMediaQuery(t => (t as Theme).breakpoints.down('sm'))
    const [showPhotos, setShowPhotos] = useState(false)
    const [saveSuccess, setSaveSuccess] = useState(false)
    const [saveFailure, setSaveFailure] = useState(false)
    const deleteJob = useCallback((j: Job) => {
        confirm({
            title: 'Delete Job',
            description: 'Are you sure you want to delete this job? All content, including uploaded photos, will be permanently erased.',
            confirmationText: 'Delete',
            confirmationButtonProps: {
                variant: 'contained',
                color: 'error'
            },
            cancellationText: 'Cancel',
            contentProps: {
                dividers: true
            }
        }).then(async () => {
            onClose()
            if (!FirebaseFirestore || !FirebaseStorage) return
            await Promise.all(j.attachments.map(file => FirebaseStorage && deleteObject(ref(FirebaseStorage, `job_photos/${file}`)).catch(e => console.log(e))))
            await deleteDoc(doc(collection(FirebaseFirestore, 'jobs'), j.id)).catch(e => console.log(e))
        }, () => { })
    }, [confirm, onClose])
    const toggleActive = useCallback((j: Job) => {
        if (!FirebaseFirestore) return
        if (jobState(j) !== JobState.CLOSED)
            confirm({
                title: 'Close Job',
                description: 'Are you sure you want to close this job? Existing photos will still be viewable, but job details will not be editable. You can re-open the job at any time.',
                confirmationText: 'Close Job',
                confirmationButtonProps: {
                    variant: 'contained',
                    color: 'error'
                },
                cancellationText: 'Cancel',
                contentProps: {
                    dividers: true
                }
            }).then(() => FirebaseFirestore && updateDoc(doc(collection(FirebaseFirestore, 'jobs'), j.id), { active: false, state: JobState.CLOSED })).catch(e => console.log(e))
        else
            updateDoc(doc(collection(FirebaseFirestore, 'jobs'), j.id), { active: true, state: JobState.ACTIVE }).catch(e => console.log(e))
    }, [confirm])
    const saveJob = useCallback((j: Job) => {
        let newJob: Job = {
            ...j,
            priority: checkboxValue('priority'),
            state: checkboxValue('bidding') ? JobState.BID : JobState.ACTIVE,
            customerName: textFieldValue('customerName').trim(),
            contactName: textFieldValue('contactName').trim(),
            phone: textFieldValue('phone').trim().replaceAll(/[^0-9]/g, ''),
            email: textFieldValue('email').trim(),
            address: {
                line1: textFieldValue('addressLine1').trim(),
                line2: textFieldValue('addressLine2').trim(),
                city: textFieldValue('addressCity').trim(),
                state: textFieldValue('addressState'),
                zip: textFieldValue('addressZip').trim()
            },
            notes: textFieldValue('notes').trim(),
            estimatedHours: numericValue('estimatedHours') || null,
            estimatedPrice: textFieldValue('estimatedPrice').trim(),
            complexity: numericValue('complexity') || 0,
            equipment: equipmentTypes.filter(e => checkboxValue(`equipment_${e}`))
        }
        let warnings: string[] = []
        if (newJob.customerName.trim().length === 0)
            warnings.push('Customer or business name is empty.')
        if (newJob.phone.length !== 0 && newJob.phone.length !== 10)
            warnings.push('Phone number is not 10 digits.')
        if (newJob.email.length > 0 && !newJob.email.includes('@'))
            warnings.push('Email address does not appear to be valid.')
        if (newJob.address.line1.length === 0)
            warnings.push('Address - line 1 is empty.')
        if (newJob.address.city.length === 0)
            warnings.push('Address - city is empty.')
        if (newJob.address.zip.length > 0 && !/^[0-9]{5}$/g.test(newJob.address.zip))
            warnings.push('Address - zip code is not 5 digits.')

        let save = () => FirebaseFirestore && setDoc(doc(collection(FirebaseFirestore, 'jobs'), j.id), { ...newJob, key: newJob.id }).then(() => setSaveSuccess(true), e => {
            console.log(e)
            setSaveFailure(true)
        })

        if (warnings.length > 0)
            confirm({
                title: "Job Details Incomplete",
                contentProps: {
                    dividers: true
                },
                content: <DialogContentText>
                    <Typography>The following fields have warnings. Do you want to save anyway?</Typography>
                    <ul>
                        {warnings.map(w => <li key={w}>{w}</li>)}
                    </ul>
                </DialogContentText>,
                confirmationText: "Save Anyway",
                confirmationButtonProps: {
                    variant: 'contained',
                    color: 'success'
                },
                cancellationText: 'Cancel'
            }).then(save, () => { })
        else 
            save()
        
    }, [confirm])
    return <IfLet value={job}>{j => <>
        <Dialog fullScreen={fullScreen} scroll='paper' open sx={{ userSelect: 'none', cursor: 'default' }}>
            <BasicSnackbar open={saveSuccess} onClose={() => setSaveSuccess(false)} color='success' message='Job details saved.'/>
            <BasicSnackbar open={saveFailure} onClose={() => setSaveFailure(false)} color='error' message='An error occurred while saving job details.'/>
            <PhotosDialog open={showPhotos} job={j} onClose={() => setShowPhotos(false)}/>
            <DialogTitle>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack direction='column'>
                        <Typography variant='h6'>{isNew ? 'Create' : 'Edit'} Job</Typography>
                        <If condition={!isNew}>
                            <Typography variant='subtitle2'>Created at {new Date(j.createdAt).toLocaleString(undefined, {
                                month: 'numeric',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                            })}</Typography>
                        </If>
                    </Stack>
                    <HSpacer />
                    <Button disabled={isNew} onClick={() => setShowPhotos(true)} variant='contained' startIcon={<Photo />}>Photos</Button>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Collapse in={jobState(j) === JobState.CLOSED}>
                    <Fade in={jobState(j) === JobState.CLOSED}>
                        <Alert severity="warning" sx={{ mb: 1 }}>
                            <AlertTitle>Closed Job</AlertTitle>
                            This job has been closed and cannot be edited. Previously uploaded photos are still viewable.
                        </Alert>
                    </Fade>
                </Collapse>
                <JobDetails job={j} />
            </DialogContent>
            <DialogActions>
                <If condition={!isNew}>
                    <Button color='error' onClick={() => deleteJob(j)}>Delete</Button>
                    <Divider flexItem variant='middle' orientation='vertical' />
                    <Button color='error' onClick={() => toggleActive(j)}>{jobState(j) !== JobState.CLOSED ? 'Close' : 'Re-open'}</Button>
                </If>
                <HSpacer />
                <Button color='primary' onClick={onClose}>Cancel</Button>
                <Button disabled={jobState(j) === JobState.CLOSED} color='success' variant='contained' onClick={() => saveJob(j)}>Save</Button>
            </DialogActions>
        </Dialog>
    </>}</IfLet>
}