import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Alert, AlertTitle, Button, Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import { ConfirmProvider } from 'material-ui-confirm';
import SearchContext from './filter/SearchContext';
import { FilterType, defaultFilter } from './filter/SearchUtil';
import { IfLet } from './util/If';
import { FirebaseErrors } from './FirebaseApp';
import PublicRequestForm from './PublicRequestForm';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#eeeeee'
    },

  }
})

theme = createTheme(theme, {
  palette: {
    atsGreen: theme.palette.augmentColor({
      color: {
        main: '#114925',
        contrastText: '#ffffff'
      },
      name: 'atsGreen'
    })
  }
})

export const estimateRequestHref = '/public/request-estimate'

function Root() {
  const [searchQuery, setSearchQuery] = useState('')
  const [filters, setFilters] = useState<FilterType>(defaultFilter)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmProvider>
        <SearchContext.Provider value={{searchQuery, setSearchQuery, filters, setFilters}}>
          <BrowserRouter>
            <Navbar />
            <Container maxWidth="lg" sx={{ mt: 3, mb: 12 }}>
              <IfLet value={FirebaseErrors}>{e => <>
                <Alert sx={{ mb: 1 }} severity='error' variant='filled' action={<Button color='inherit' size='small' onClick={() => { window.location.reload() }}>Reload Page</Button>}>
                  <AlertTitle>Fatal Error</AlertTitle>
                  A fatal error occurred while loading the app: {JSON.stringify(e)}
                </Alert>
              </>}</IfLet>
                <Routes>
                  <Route path='/' Component={App} />
                  <Route path={estimateRequestHref} Component={PublicRequestForm} />
                </Routes>
            </Container>
          </BrowserRouter>
        </SearchContext.Provider>
      </ConfirmProvider>
    </ThemeProvider>
  )
}

root.render(
  <React.StrictMode>
    <Root/>
  </React.StrictMode>
);
