import { Stack, Chip, Typography, Fade, Alert, Button } from "@mui/material"
import { useMemo, useState } from "react"
import { Job } from "../util/Types"
import JobCard from "./JobCard"
import HSpacer from "../util/HSpacer"
import { Link } from "@mui/icons-material"
import BasicSnackbar from "../util/BasicSnackbar"
import If from "../util/If"
import { estimateRequestHref } from ".."

export default function JobSection(props: { onSelect: (p0: Job) => void, jobs: Job[], totalCount: number, title: string, bids: boolean }) {
    const filtersActive = useMemo(() => props.jobs.length !== props.totalCount, [props.jobs, props.totalCount])
    let [copyNotification, setCopyNotification] = useState(false)
    return <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={2} sx={{ alignItems: 'center', py: 1 }}>
            <Chip color={filtersActive ? 'info' : 'default'} label={filtersActive ? `${props.jobs.length}/${props.totalCount}` : props.jobs.length} />
            <Typography variant='h5'>{props.title}</Typography>
            <If condition={props.bids}>
                <HSpacer />
                <Button onClick={() => {
                    let url = new URL(window.location.toString())
                    url.pathname = estimateRequestHref
                    navigator.clipboard.writeText(`${url.toString()}`).then(() => setCopyNotification(true))
                }} size='small' color="atsGreen" variant="contained" startIcon={<Link />}>Request Estimate</Button>
            </If>
        </Stack>
        {props.jobs.map(j => <JobCard key={j.id} job={j} bids={props.bids} onClick={() => props.onSelect(j)} />)}

        <Fade unmountOnExit in={props.jobs.length === 0}>
            <Alert severity='info'>No jobs found.</Alert>
        </Fade>
        <BasicSnackbar open={copyNotification} onClose={() => setCopyNotification(false)} color='success' message='Estimate request form URL copied to clipboard.'/>
    </Stack>
}